<template>
  <video
    ref="videoRef"
    v-bind="attrs"
    webkit-playsinline="true"
    x-webkit-airplay="true"
    x5-playsinline
    x5-video-player-type="h5"
    x5-video-orientation="h5"
    preload="auto"
    :poster="poster"
  >
    <slot name="source"></slot>
  </video>
</template>
<script lang="ts" setup>
import { VideoHTMLAttributes } from "vue"

interface VideoProps {
  attributes?: VideoHTMLAttributes
  src?: VideoHTMLAttributes["src"]
  autoplay?: VideoHTMLAttributes["autoplay"]
  poster?: VideoHTMLAttributes["poster"]
}
const defaultProps = {
  muted: true,
  loop: true,
  playsinline: true // 修复ios系统 video自动弹窗问题
}

const props = withDefaults(defineProps<VideoProps>(), {
  attributes() {
    return {}
  },
  src: "",
  autoplay: true,
  poster: ""
})

const attrs = computed<VideoHTMLAttributes>(() => {
  const data = {
    ...defaultProps,
    autoplay: props.autoplay,
    ...props.attributes
  }
  if(props.src){
    data.src = props.src
  }
  return data
})
const videoRef = ref<HTMLVideoElement>()

async function playVideo() {
  try {
    await videoRef.value?.play()
    return true
  } catch (error) {
    return false
  }
}

function pauseVideo() {
  videoRef.value?.pause()
}

const { y } = useWindowScroll()
let stopWatch
function autoPlayOnMobile() {
  // ios 微信直接调用SDK
  if (typeof window.WeixinJSBridge !== "undefined" && isIOS.value) {
    window.WeixinJSBridge.invoke("getNetworkType", {}, (e) => {
      videoRef.value?.play()
    })
  }
  if (isAndroid) {
    // 安卓微信需要用户交互才可以播放 不然会抛出异常
    stopWatch = watch(y, useThrottle(watchHandler, 300))
  }
}
async function watchHandler(v) {
  if (v) {
    const valid = await playVideo()
    if (valid) {
      stopWatch && stopWatch()
    }
  }
}
const { isAndroid, isIOS } = useDevice()
onMounted(async () => {
  await nextTick()
  if (isAndroid.value || isIOS.value) {
    props.autoplay && autoPlayOnMobile()
  }
})

defineExpose({
  play: playVideo,
  pause: pauseVideo,
  video: videoRef,
  autoPlayOnMobile: autoPlayOnMobile
})
</script>
